import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
    Box, 
    Typography, 
    Button, 
    Card, 
    CardContent, 
    CircularProgress, 
    Alert
  } from '@mui/material';
  import Grid from '@mui/material/Grid2';

  const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

interface Patient {
  id: number;
  username: string;
  location: string;
}

const MyPatients: React.FC = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const therapistInfo = JSON.parse(localStorage.getItem('therapistInfo') || '{}');
  const therapistId = therapistInfo?.id; // Replace with actual therapist ID from auth context or route params.

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/therapist/${therapistId}/patients`);
      setPatients(response.data.data.map((item: any) => item.patient));
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  return (
    <div className="container mt-5">
      <Typography variant="h6">My Patients</Typography>
                <hr />
                <br /><br />
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {patients.length > 0 ? (
            patients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{index + 1}</td>
                <td>{patient.username}</td>
                <td>{patient.location}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                No patients assigned.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MyPatients;
