import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';


//Define types

interface TherapistAuthContextType {
    isAuthenticated: boolean;
    therapistInfo: Record<string, any> | null;
    login: (patient: Record<string, any>) => void;
    logout: () => void;
}

const TherapistAuthContext = createContext<TherapistAuthContextType | undefined>(undefined);

//create a provider

export const TherapistAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [therapistInfo, setTherapistInfo] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    // This ensures that the state is initialized based on localStorage
    const token = localStorage.getItem('therapistToken');
    const info = localStorage.getItem('therapistInfo');

    if (token && info) {
      setIsAuthenticated(true);
      setTherapistInfo(JSON.parse(info));
    }
  }, []);


  const login = (therapist: Record<string, any>) => {
    localStorage.setItem('therapistToken', therapist.token);
    localStorage.setItem('therapistInfo', JSON.stringify(therapist));
    setIsAuthenticated(true);
    setTherapistInfo(therapist);
    
    // Reload the page after logging in to reflect changes in the Navbar
    window.location.reload();
  };

  const logout = () => {
    localStorage.removeItem('therapistToken');
    localStorage.removeItem('therapistInfo');
    setIsAuthenticated(false);
    setTherapistInfo(null);
    
    // Reload the page after logging out to reflect changes in the Navbar
    window.location.reload();
  };

  return (
    
    <TherapistAuthContext.Provider value={{ isAuthenticated, therapistInfo, login, logout }}>
      {children}
    </TherapistAuthContext.Provider>
  );
};

// Custom hook for convenience
export const useTherapistAuth = (): TherapistAuthContextType => {
  const context = useContext(TherapistAuthContext);
  if (!context) {
    throw new Error('useTherapistAuth must be used within an AuthProvider');
  }
  return context;
};