import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CircularProgress, 
  Alert
} from '@mui/material';
import Grid from '@mui/material/Grid2';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

interface Therapist {
  id: number;
  firstname: string;
  lastname: string;
  location: string;
  specialization: string;
  experience: string;
}
const MyTherapists = () => {
  const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // To track the request status
    const [success, setSuccess] = useState(false); // To show success message
    const [error, setError] = useState<string | null>(null); // To track error messages
    const [therapist, setTherapist] = useState<Therapist | null>(null);
    const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');
  
    
    useEffect(() => {
      const fetchTherapist = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${BASE_API_URL}/assigned-therapists/${patientInfo?.id}`);
          setTherapist(response.data.data);
        } catch (err: any) {
          setError(err.response?.data?.message || 'Something went wrong');
        } finally {
          setLoading(false);
        }
      };
  
      fetchTherapist();
    }, [patientInfo?.id]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }
  
    if (!therapist) {
      return <div className="alert alert-warning">No therapist assigned.</div>;
    }
  
    const handleTherapistRequest = async () => {
      setLoading(true);
      setSuccess(false);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_API_URL}/therapist-requests`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('patientToken')}`,
          },
          body: JSON.stringify({
            patient_id: patientInfo?.id, // Assuming `id` exists in patientInfo
          }),
        });
  
        if (response.ok) {
          setSuccess(true);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to send therapist request. Please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
  return (
    <>
    <div className="container">
      <Box sx={{ padding: '2rem' }}>
          <Typography variant="h6">My Therapists</Typography>
          <hr />
          <br /><br />

          <Card sx={{ marginBottom: '1rem' }}>
          <CardContent>
          <Typography variant="h6" gutterBottom>
          {therapist.firstname} {therapist.lastname}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}>
          <strong>Location:</strong> {therapist.location}<br />
          <strong>Specialization:</strong> {therapist.specialization}<br />
          <strong>Experience:</strong> {therapist.experience} years
                </Typography>
          </CardContent>
        </Card>

                      {/* /* Request Therapist Card */} 
             <Card sx={{ marginBottom: '1rem', marginTop: '2rem' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Request a Therapist
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}>
                  Click the button below to raise a request for a therapist.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTherapistRequest}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : 'Request Therapist'}
                </Button>

                {success && (
                  <Alert severity="success" sx={{ marginTop: '1rem' }}>
                    Request sent successfully!
                  </Alert>
                )}
                {error && (
                  <Alert severity="error" sx={{ marginTop: '1rem' }}>
                    {error}
                  </Alert>
                )}
              </CardContent>
            </Card>
      </Box>
    </div>
    </>
  )
}

export default MyTherapists