import React from 'react'

const Footer: React.FC = () =>  {
  return (
    <div>
    <div className="footer section padded">
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <h1 className="brandname">Simir</h1>
          <p className="tagline">For the betterment of your mental health</p>
        </div>
        <div className="col-md-2">
          <a href="/about" className="footer-link">Company</a>
          <a href="/about" className="footer-link">About</a>
          {/* <a href="#" className="footer-link">Advice</a> */}
          <a href="/faq" className="footer-link">FAQs</a>
         </div>
        <div className="col-md-2">
          <a href="/reviews" className="footer-link">Reviews</a>
          <a href="/therapist-jobs" className="footer-link">Therapist Jobs</a>
          <a href="/therapist-login" className="footer-link">Therapist Login</a>
          <a href="/contact" className="footer-link">Contact</a>
        </div>
        <div className="col-md-3">
                <h4 className="text-white">Follow Us on Social.</h4>
                <br></br>
                <a href="#" className="social-link"><i className="fa-brands fa-fw fa-facebook-f"></i></a>
                <a href="#" className="social-link"><i className="fa-brands fa-fw fa-instagram"></i></a>
                <a href="#" className="social-link"><i className="fa-brands fa-fw fa-twitter"></i></a>
                <a href="#" className="social-link"><i className="fa-brands fa-fw fa-linkedin"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div className="section colophon">
      <p>&copy; 2024. Simir.com. All Rights Reserved.</p>
  </div>
  </div>
  )
}

export default Footer