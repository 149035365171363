import React from 'react'
import {Navigate, Outlet } from 'react-router-dom';


const TherapistProtectedRoute: React.FC = () => {
    const isAuthenticated = !!localStorage.getItem('therapistToken');

    return isAuthenticated ? <Outlet /> : <Navigate to="/therapist-login" />;
}

export default TherapistProtectedRoute