import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CircularProgress, 
  Alert
} from '@mui/material';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const MyPayments: React.FC = () => {

  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');
  const patientId = patientInfo?.id;

  const handlePaymentSuccess = async (details: any) => {
    try {
      const paymentData = {
        patient_id: patientId,
        amount: details.purchase_units[0].amount.value,
        transaction_id: details.id,
        status: details.status,
      };

      // Save payment details to the backend.
      await axios.post(`${BASE_API_URL}/payments`, paymentData);

      setPaymentStatus("Payment successful!");
    } catch (error) {
      setPaymentStatus("Error saving payment. Please contact support.");
      console.error("Payment save error:", error);
    }
  };


  return (
    <div className="container mt-5">
      <Box sx={{ padding: '2rem' }}>
          <Typography variant="h6">My Payments</Typography>
          <hr />
          <br /><br />
      <p>The payment amount is $10. Please proceed with PayPal to complete the transaction.</p>
      <PayPalScriptProvider
        options={{
          "clientId": "AV1Xpjy-KESXLOJ038s2vx6x5pTbIAulf8YZSe9IVCf9dfsc3SG_Wj-hP17k1D6K4k9_vnD5Fza7O3Nu",
        }}
      >
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: "10.00",
                  },
                },
              ],
              intent: "CAPTURE"
            });
          }}
          onApprove={async (data, actions) => {
            const details = await actions.order?.capture();
            if (details) {
              handlePaymentSuccess(details);
            }
          }}
          onError={(err) => {
            console.error("PayPal Payment Error:", err);
            setPaymentStatus("Payment failed. Please try again.");
          }}
        />
      </PayPalScriptProvider>
      {paymentStatus && <div className="alert alert-info mt-3">{paymentStatus}</div>}
      </Box>
    </div>
  );

}

export default MyPayments