import logo from "../assets/images/simir_logo.png";
import React from "react";
import { useAuth } from "../context/AuthContext"; // Patient Auth
import { useTherapistAuth } from "../context/TherapistAuthContext"; // Therapist Auth

const Navbar: React.FC = () => {
  const { isAuthenticated: isPatientAuthenticated, patientInfo, logout: patientLogout } = useAuth();
  const { isAuthenticated: isTherapistAuthenticated, therapistInfo, logout: therapistLogout } = useTherapistAuth();

  const handleLogout = () => {
    if (isPatientAuthenticated) {
      patientLogout();
    } else if (isTherapistAuthenticated) {
      therapistLogout();
    }
  };

  const renderDropdown = () => {
    if (isPatientAuthenticated) {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {patientInfo?.username}
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a href="/patient-dashboard" className="dropdown-item nav-link">Dashboard</a></li>
            <li>
              <a className="dropdown-item nav-link" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </li>
      );
    }

    if (isTherapistAuthenticated) {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {therapistInfo?.firstname} {therapistInfo?.lastname}
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a href="/therapist-dashboard" className="dropdown-item nav-link">Dashboard</a></li>
            <li>
              <a className="dropdown-item nav-link" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </li>
      );
    }

    return null;
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" width="180" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/reviews">
                Reviews
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/faq">
                FAQs
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li>
            {isPatientAuthenticated || isTherapistAuthenticated ? (
              renderDropdown()
            ) : (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/therapist-jobs">
                    Therapist Jobs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/login">
                    Login
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/get-started">
                    Get Started
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
