import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CircularProgress, 
  Alert
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Navbar from '../../components/Navbar';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const PatientDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // To track the request status
  const [success, setSuccess] = useState(false); // To show success message
  const [error, setError] = useState<string | null>(null); // To track error messages

  const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');

  useEffect(() => {
    const token = localStorage.getItem('patientToken');
    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [navigate]);

  
  return (
    <>
      <div className="container">
        <Box sx={{ padding: '2rem' }}>
          <Typography variant="h6">Welcome, {patientInfo?.username || 'Patient'}</Typography>
            <hr/>
            <br/><br />
            <Grid container spacing={2}>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#c7ebf6' }}>
                  <CardContent>
                    <h5>My Therapists</h5>
                    <br/>
                    <p>Click here to view and interact with your assigned therapists.</p>
                    <a className="btn btn-main" href="/my-therapists">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#fff1c1'}}>
                  <CardContent>
                    <h5>Appointments</h5>
                    <br/>
                    <p>Click here to book and view all your upcoming appointments with your therapists.</p>
                    <a className="btn btn-main" href="/my-appointments">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#e1f6c7'}}>
                  <CardContent>
                    <h5>Payments</h5>
                    <br/>
                    <p>Click here to manage your subscription and payment status.</p>
                    <a className="btn btn-main" href="/my-payments">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
        </Box>
        <br/><br/><br />
      </div>
    </>
  );
};

export default PatientDashboard;
