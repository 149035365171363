import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { 
    Box, 
    Typography, 
  } from '@mui/material';

  const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
const localizer = momentLocalizer(moment);

const MyCalendar: React.FC = () => {
  const [slots, setSlots] = useState<any[]>([]);
  const [newSlot, setNewSlot] = useState({ date: new Date(), startTime: '', endTime: '' });
  const therapistInfo = JSON.parse(localStorage.getItem('therapistInfo') || '{}');
  const therapistId = therapistInfo?.id; // Replace with actual therapist ID (e.g., from auth context or route params)
  useEffect(() => {
    fetchSlots();
  }, []);


  const fetchSlots = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/therapist/${therapistId}/slots`);
      setSlots(
        response.data.map((slot: any) => ({
          title: `${slot.status}`,
          start: new Date(`${slot.date}T${slot.start_time}`),
          end: new Date(`${slot.date}T${slot.end_time}`),
        }))
      );
    } catch (error) {
      console.error('Error fetching slots:', error);
    }
  };
  
  const handleAddSlot = async () => {
    try {
      const { date, startTime, endTime } = newSlot;
      await axios.post(`${BASE_API_URL}/therapist/${therapistId}/slots`, {
        date: moment(date).format('YYYY-MM-DD'),
        start_time: startTime,
        end_time: endTime,
      });
      fetchSlots(); // Refresh slots after adding
      setNewSlot({ date: new Date(), startTime: '', endTime: '' });
    } catch (error) {
      console.error('Error adding slot:', error);
    }
  };
  

  return (
<div className="container">
      <Box sx={{ padding: '2rem' }}>
          <Typography variant="h6">My Calendar</Typography>
          <hr />
          <br /><br />
      <div style={{ height: 500 }}>
        <Calendar
          localizer={localizer}
          events={slots}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
      <div className="mt-4">
        <h4>Add New Slot</h4>
        <DatePicker
        className="p-1 mx-1"
          selected={newSlot.date}
          onChange={(date) => setNewSlot({ ...newSlot, date: date || new Date() })}
        />
        <input
        className="p-1 mx-1"
          type="time"
          value={newSlot.startTime}
          onChange={(e) => setNewSlot({ ...newSlot, startTime: e.target.value })}
          placeholder="Start Time"
        />
        <input
          className="p-1 mx-1"
          type="time"
          value={newSlot.endTime}
          onChange={(e) => setNewSlot({ ...newSlot, endTime: e.target.value })}
          placeholder="End Time"
        />
        <button onClick={handleAddSlot} className="btn btn-success btn-sm mx-2">Add Slot</button>
      </div>
      </Box>
    </div>
  );
};

export default MyCalendar;
