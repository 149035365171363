import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  CircularProgress, 
  Alert
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Navbar from '../../components/Navbar';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const TherapistDashboard: React.FC = () => {
  const navigate = useNavigate();
  const therapistInfo = JSON.parse(localStorage.getItem('therapistInfo') || '{}');

  useEffect(() => {
    const token = localStorage.getItem('therapistToken');
    if(!token) {
      navigate('/therapist-login');
    }
  }, [navigate]);


  return (
    <>
      <div className="container">
        <Box sx={{ padding: '2rem' }}>
          <Typography variant="h6">Welcome, {therapistInfo?.firstname} {therapistInfo?.lastname || 'therapist'}</Typography>
            <hr/>
            <br/><br />
            <Grid container spacing={2}>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#c7ebf6' }}>
                  <CardContent>
                    <h5>My Calendar</h5>
                    <br/>
                    <p>Click here to create available slots for patients to book.</p>
                    <a className="btn btn-main" href="/my-calendar">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#fff1c1'}}>
                  <CardContent>
                    <h5>Patients</h5>
                    <br/>
                    <p>Click here to view all the patients assigned to you by Simir.</p>
                    <a className="btn btn-main" href="/my-patients">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
              <Grid size={4}>
                <Card sx={{ marginBottom: '1rem', backgroundColor: '#e1f6c7'}}>
                  <CardContent>
                    <h5>Sessions</h5>
                    <br/>
                    <p>Click here to manage your upcoming sessions with patients.</p>
                    <a className="btn btn-main" href="#">Learn More</a>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
        </Box>
        <br/><br/><br />
      </div>
    </>
  );
}

export default TherapistDashboard