import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import Navbar from '../components/Navbar';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const TherapistLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await fetch(`${BASE_API_URL}/therapist/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }
  
      // Save token and patient data in localStorage
      localStorage.setItem('therapistToken', data.token);
      localStorage.setItem('therapistInfo', JSON.stringify(data.therapist));
      navigate('/therapist-dashboard');
      // Reload the page after login to update the Navbar
      window.location.reload();
  
      // Redirect to dashboard
      navigate('/therapist-dashboard'); // This can be omitted since the page will reload
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Box sx={{ width: '100%', maxWidth: '400px', margin: 'auto', marginTop: '5rem', marginBottom: '5rem' }}>
      <Typography variant="h5" sx={{ marginBottom: '2rem' }}>
        Therapist Login
      </Typography>
      {error && <Alert severity="error" sx={{ marginBottom: '1rem' }}>{error}</Alert>}
      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ marginBottom: '1rem' }}
      />
      <TextField
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ marginBottom: '1rem' }}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={handleLogin}
        disabled={loading}
        sx={{ marginTop: '1rem' }}
      >
        {loading ? 'Logging in...' : 'Login'}
      </Button>
    </Box>
    </>
  );
};

export default TherapistLogin;
