import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const MyAppointments: React.FC = () => {
  const [availableSlots, setAvailableSlots] = useState<any[]>([]);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [loadingSlots, setLoadingSlots] = useState(true);
  const [loadingAppointments, setLoadingAppointments] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const patientInfo = JSON.parse(localStorage.getItem('patientInfo') || '{}');
  const patientId = patientInfo?.id; // Adjust as needed

  useEffect(() => {
    fetchAvailableSlots();
    fetchAppointments();
  }, []);

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(':'); // Split the time into hours and minutes
    const date = new Date();
    
    // Set hours and minutes to the current date's time
    date.setHours(Number(hours), Number(minutes), 0);
  
    // Format the time using toLocaleTimeString
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };
  const fetchAvailableSlots = async () => {
    setLoadingSlots(true);
    try {
      const response = await axios.get(`${BASE_API_URL}/patient/${patientId}/available-slots`);
      setAvailableSlots(response.data.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setError('Failed to fetch available slots.');
    } finally {
      setLoadingSlots(false);
    }
  };

  const fetchAppointments = async () => {
    setLoadingAppointments(true);
    try {
      const response = await axios.get(`${BASE_API_URL}/patient/${patientId}/appointments`);
      setAppointments(response.data.data);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setError('Failed to fetch appointments.');
    } finally {
      setLoadingAppointments(false);
    }
  };

  const bookSlot = async (slotId: number) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/book-appointment`, {
        patient_id: patientId,
        slot_id: slotId,
      });
      alert(response.data.message);
      fetchAvailableSlots();
      fetchAppointments();
    } catch (error) {
      console.error('Error booking slot:', error);
      setError('Failed to book the slot. Please try again.');
    }
  };

  return (
    <>
    <div className="container">
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        My Appointments
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}

      <Typography variant="h6" gutterBottom>
        Available Slots
      </Typography>
      {loadingSlots ? (
        <CircularProgress />
      ) : availableSlots.length === 0 ? (
        <Typography>No available slots at the moment.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ marginBottom: '2rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableSlots.map((slot) => (
                <TableRow key={slot.id}>
                  <TableCell>{new Date(slot.start_time).toLocaleDateString()}</TableCell>
                  <TableCell>
                  {formatTime(slot.start_time)} - {formatTime(slot.end_time)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => bookSlot(slot.id)}
                    >
                      Book
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="h6" gutterBottom>
        Upcoming Appointments
      </Typography>
      {loadingAppointments ? (
        <CircularProgress />
      ) : appointments.length === 0 ? (
        <Typography>No upcoming appointments.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Therapist</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>
                    {new Date(appointment.slot.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {formatTime(appointment.slot.start_time)} - {formatTime(appointment.slot.end_time)}
                  </TableCell>
                  <TableCell>
                    {`${appointment.therapist.name}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
    </div>
    </>
  );
};

export default MyAppointments;
